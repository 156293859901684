.swiper-button-prev,
.swiper-button-next {
  z-index: 11 !important;
}

/* TODO: svg で表現する */
.swiper-button-prev:after,
.swiper-button-next:after {
  font-weight: 900 !important;
}
